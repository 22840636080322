/**
 * Copyright (C) Sitevision AB 2002-2024, all rights reserved
 *
 * @author albin
 */

import Class from 'class.extend';
import events from 'events';
import Router from './Router';
import I18n from './i18n';
import App from './App';
import * as requester from './requester';
import * as toasts from './toasts';
import * as security from './security';

const AppRegistry = window.AppRegistry;
const REQUIRE_REG_EX = /[^.]\s*require\s*\(\s*["']([^'"\s]+)["']\s*\)/g;
const COMMENTS_REG_EX = /(\/\*([\s\S]*?)\*\/|([^:]|^)\/\/(.*)$)/gm;
const REACT_REG_EX = /^(react|react-dom)$/;
const globalEvents = Object.assign({}, events);

const getExternalReact = function (version) {
  const versionArr = version.split('.');
  const major = versionArr[0];
  const minor = versionArr[1];

  return window.sv.UNSAFE_MAY_CHANGE_AT_ANY_GIVEN_TIME_webAppExternals[
    'react_' + major + '_' + minor
  ];
};

const WebApp = Class.extend({
  init: function (context, bundle, bootstrapData) {
    this.requiredLibs = context.requiredLibs;
    this.app = new App(context);
    this.router = new Router(context);

    const i18nObj = new I18n(context.locale, context.defaultLocale, bundle);

    this.i18n = i18nObj.get.bind(i18nObj);
    this.i18n.get = i18nObj.get.bind(i18nObj); // This is to conform with the server api
    this.initialState = AppRegistry.getInitialState(this.app.portletId);
    this.agnosticRender = bootstrapData && bootstrapData.AGNOSTIC_RENDERER;

    this.bootstrapData = bootstrapData;
    this.modules = {
      app: this.app,
      router: this.router,
      i18n: this.i18n,
      events: globalEvents,
      //The spread is a workaround for what seems to be a bug in webpack
      requester: { ...requester },
      toasts: { ...toasts },
      security: { ...security },
      url: {
        get: this.router.getUrl.bind(this.router),
      },
    };

    this.definitions = {};

    this.definitionQue = [];
  },

  start: function () {
    this.startModules();

    if (this.agnosticRender) {
      const main = this.require('/main');
      const element = document.querySelector(
        '[data-cid="' + this.app.portletId + '"]',
      );

      if (main.default) {
        main.default(this.initialState, element);
      } else if (typeof main === 'function') {
        main(this.initialState, element);
      }
    }
  },

  require: function (path) {
    if (this.requiredLibs.react && REACT_REG_EX.test(path)) {
      return getExternalReact(this.requiredLibs.react)[path];
    }

    return this.modules[path];
  },

  define: function (path, deps, callback) {
    let usesRequireFunction = false;

    if (!callback) {
      callback = deps;
      deps = undefined;
      usesRequireFunction = true;
    }

    if (!deps && typeof callback === 'function') {
      deps = [];
      if (callback.length) {
        callback
          .toString()
          .replace(COMMENTS_REG_EX, '')
          .replace(REQUIRE_REG_EX, function (match, dep) {
            deps.push(dep);
          });
      }
    }

    this.definitions[path] = {
      path: path,
      callback: callback,
      deps: deps,
      usesRequireFunction: usesRequireFunction,
    };

    this.definitionQue.push({
      path: path,
      deps: deps,
      callback: callback,
      usesRequireFunction: usesRequireFunction,
    });
  },

  startModule: function (definition) {
    if (this.modules[definition.path]) {
      return;
    }

    definition.deps.forEach(function (dep) {
      let module;

      if (Object.keys(this.modules).indexOf(dep) !== -1) {
        return;
      }

      if (REACT_REG_EX.test(dep)) {
        return;
      }

      module = this.definitions[dep];
      if (!module) {
        window.console.error(
          'Error in: ' +
            this.app.webAppId +
            '. Missing dependency "' +
            dep +
            '" in module ' +
            definition.path,
        );
        return;
      }

      this.startModule(this.definitions[dep]);
    }, this);

    if (definition.usesRequireFunction) {
      /**
       * define(function (require) {
       *   const depA = require('depA');
       *   const depB = require('depB');
       *
       *   ...
       * })
       */
      this.modules[definition.path] = definition.callback(
        this.require.bind(this),
      );
    } else {
      /**
       * define(['depA', 'depB'], function (depA, depB) {
       *   ...
       * })
       */
      this.modules[definition.path] = definition.callback.apply(
        null,
        definition.deps.map((dep) => {
          if (this.requiredLibs.react && REACT_REG_EX.test(dep)) {
            return getExternalReact(this.requiredLibs.react)[dep];
          }

          return this.modules[dep];
        }),
      );
    }
  },

  startModules: function () {
    this.definitionQue.forEach(this.startModule, this);
  },

  createDefine: function (path) {
    return this.define.bind(this, path);
  },
});

export default WebApp;
