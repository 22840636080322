/**
 * Copyright (C) Sitevision AB 2002-2024, all rights reserved
 *
 * @author albin
 */
import Class from 'class.extend';
import events from 'events';

function getChangedQueryParameterKeys(
  oldQueryStringObject,
  newQueryStringObject
) {
  var changed = [];

  Object.entries(newQueryStringObject).forEach(function ([key, value]) {
    var oldValue = oldQueryStringObject[key];

    if (oldValue !== value) {
      changed.push(key);
    }
  });

  Object.keys(oldQueryStringObject).forEach(function (key) {
    if (!Object.hasOwn(newQueryStringObject, key)) {
      changed.push(key);
    }
  });

  return changed;
}

function getQueryStringAsObject(queryString, pathParameterName) {
  var pairs = queryString.length ? queryString.slice(1).split('&') : [],
    result = {};

  pairs.forEach(function (pair) {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });

  return Object.fromEntries(
    Object.entries(result).filter(
      ([key]) => key !== 'sv.target' && key !== pathParameterName
    )
  );
}

function getPathFromUrl(url, pathParameterName) {
  var queryString = url.substring(url.indexOf('?') + 1),
    params = queryString.split('&'),
    routeParam = params.find(function (param) {
      return param.indexOf(pathParameterName) === 0;
    }),
    path = routeParam ? routeParam.split('=')[1] : '/';

  return decodeURIComponent(path);
}

function getQueryString(queryParamsObject) {
  var result = [];

  Object.entries(queryParamsObject).forEach(function ([key, value]) {
    result.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
  });

  return result.join('&');
}

const Router = Class.extend(
  Object.assign(
    {
      init: function (options) {
        this.history = window.history;
        this.location = window.location;
        this.portletId = options.portletId;

        this.pathParameterName = 'sv.' + this.portletId + '.route';
        this.currentQueryString = this.location.search;
        this.currentPath = getPathFromUrl(
          this.location.href,
          this.pathParameterName
        );

        window.addEventListener(
          'popstate',
          this._handleRouteChange.bind(this),
          false
        );
      },

      _handleRouteChange: function () {
        var path = getPathFromUrl(this.location.href, this.pathParameterName),
          newQueryStringAsObject = getQueryStringAsObject(
            this.location.search,
            this.pathParameterName
          ),
          oldQueryStringAsObject = getQueryStringAsObject(
            this.currentQueryString,
            this.pathParameterName
          ),
          changedQueryParamKeys = getChangedQueryParameterKeys(
            oldQueryStringAsObject,
            newQueryStringAsObject
          );

        if (path !== this.currentPath) {
          this.currentPath = path;

          this.trigger('path:changed', {
            path: path,
            url: this.location.search,
          });
        }

        this.currentQueryString = this.location.search;

        if (changedQueryParamKeys.length) {
          var eventOptions = {
            queryParams: newQueryStringAsObject,
            url: this.location.search,
          };

          changedQueryParamKeys.forEach(function (key) {
            this.trigger('query:changed:' + key, eventOptions);
          }, this);

          if (changedQueryParamKeys.length) {
            this.trigger('query:changed', eventOptions);
          }
        }
      },

      getUrl: function (path, queryParams) {
        var realPath =
            Object.keys(path).length === 0
              ? getPathFromUrl(this.location.search, this.pathParameterName)
              : path,
          url =
            '?sv.target=' +
            this.portletId +
            '&' +
            this.pathParameterName +
            '=' +
            realPath;

        Object.entries(queryParams).forEach(function ([key, value]) {
          url +=
            '&' +
            (value === true
              ? encodeURIComponent(key)
              : encodeURIComponent(key) + '=' + encodeURIComponent(value));
        });

        return url;
      },

      getStandaloneUrl: function (path, queryParams) {
        var pageId = window.sv.PageContext.pageId;

        if (!pageId) {
          return '/';
        }

        var dashboardId = window.sv.PageContext.dashboardId;
        var url;

        if (dashboardId) {
          url =
            '/edit-dashboard/' +
            pageId +
            '/' +
            dashboardId +
            '/' +
            this.portletId +
            path;
        } else {
          url = '/appresource/' + pageId + '/' + this.portletId + path;

          if (window.sv.PageContext.inEdit && !url.includes('version=')) {
            queryParams = queryParams || {};
            queryParams = Object.assign({ version: 0 }, queryParams);
          }
        }

        if (queryParams) {
          url += '?' + getQueryString(queryParams);
        }

        return url;
      },

      parseQueryString: function () {
        return getQueryStringAsObject(
          this.location.search,
          this.pathParameterName
        );
      },

      navigate: function (url, options) {
        const path = url
          ? getPathFromUrl(url, this.pathParameterName)
          : getPathFromUrl(this.location.href, this.pathParameterName);
        const queryParams = options && options.queryParams;
        const replace = options && options.replace;
        const newUrl = this.getUrl(path, queryParams);
        const newQueryStringAsObject = getQueryStringAsObject(
          newUrl,
          this.pathParameterName
        );
        const oldQueryStringAsObject = getQueryStringAsObject(
          this.location.search,
          this.pathParameterName
        );
        const changedQueryParamKeys = getChangedQueryParameterKeys(
          oldQueryStringAsObject,
          newQueryStringAsObject
        );

        this.history[replace ? 'replaceState' : 'pushState'](
          {},
          document.title,
          newUrl
        );

        if (path !== this.currentPath) {
          this.currentPath = path;

          this.trigger('path:changed', {
            path: path,
            url: newUrl,
          });
        }

        this.currentQueryString = newUrl;

        if (changedQueryParamKeys.length) {
          var eventOptions = {
            queryParams: newQueryStringAsObject,
            url: newUrl,
          };

          changedQueryParamKeys.forEach(function (key) {
            this.trigger('query:changed:' + key, eventOptions);
          }, this);

          this.trigger('query:changed', eventOptions);
        }
      },
    },
    events
  )
);

export default Router;
